<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="$t('cip.dc.DComission.InterfaceCollectionAdd')"
      @head-save="headSave('save')"
      @head-save-back="headSave('back')"
      @head-cancel="headCancel"
    ></head-layout>
    <div class="newZtFormBox">
      <el-form
        ref="ruleForm"
        :model="form"
        :rules="rules"
        label-width="120px"
        style="background-color: white; margin-top: 10px"
      >
        <el-collapse v-model="activeName">
          <el-collapse-item
            name="1"
            :title="$t('cip.dc.QTemplate.field.field')"
          >
            <template slot="title">
              <i
                class="avue-group__title avue-group__header"
                style="
                  color: #999999 !important;
                  font-style: normal;
                  padding: 0 10px;
                "
                ><i
                  class="el-icon-document"
                  style="font-size: 20px; padding: 0 10px"
                ></i>
                {{ $t("cip.qm.business.qm.batch.field.baseInfo") }}
              </i>
            </template>
            <el-row>
              <el-col :span="8">
                <el-form-item
                  :label="$t('cip.dc.zljkcj.field.InterfaceCode')"
                  prop="infCode"
                >
                  <el-input
                    v-model="form.infCode"
                    autocomplete="off"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  :label="$t('cip.dc.zljkcj.field.InterfaceName')"
                  prop="infName"
                >
                  <el-input
                    v-model="form.infName"
                    :placeholder="$t('cip.dc.zljkcj.field.InterfaceName')"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  :label="$t('cip.dc.zljkcj.field.InterfaceAddress')"
                  prop="infUrl"
                >
                  <el-input
                    v-model="form.infUrl"
                    :placeholder="$t('cip.dc.zljkcj.field.InterfaceAddress')"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item
                  :label="$t('cip.dc.zljkcj.field.RequestMethod')"
                  prop="requestMethod"
                >
                  <el-select
                    v-model="form.requestMethod"
                    :placeholder="$t('cip.dc.zljkcj.field.RequestMethod')"
                  >
                    <el-option :value="1" label="get"></el-option>
                    <el-option :value="2" label="post"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  :label="$t('cip.dc.zljkcj.field.SourceSystem')"
                  prop="sourceId"
                >
                  <el-select
                    v-model="form.sourceId"
                    filterable
                    clearable
                    remote
                    reserve-keyword
                    :placeholder="$t('cip.dc.DComission.selectPlaceholder')"
                    :remote-method="GetInfsystemList"
                    :loading="loading"
                  >
                    <el-option
                      v-for="item in SystemList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('cip.dc.DComission.startTime')">
                  <el-date-picker
                    v-model="form.stepTime"
                    :placeholder="$t('cip.dc.DComission.startTime')"
                    style="width: 100%"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item
                  :label="$t('cip.dc.zljkcj.field.StartTimeField')"
                  prop="startTimeField"
                >
                  <el-input
                    v-model="form.startTimeField"
                    :placeholder="$t('cip.dc.zljkcj.field.StartTimeField')"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  :label="$t('cip.dc.zljkcj.field.Endtimefield')"
                  prop="endTimeField"
                >
                  <el-input
                    v-model="form.endTimeField"
                    :placeholder="$t('cip.dc.zljkcj.field.Endtimefield')"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('cip.dc.DComission.tsField')" prop="tsField">
                  <el-input
                    v-model="form.tsField"
                    :placeholder="$t('cip.dc.DComission.tsField')"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8">
                <el-form-item :label="$t('cip.dc.zljkcj.field.TokenField')">
                  <el-input
                    v-model="form.tokenField"
                    :placeholder="$t('cip.dc.zljkcj.field.TokenField')"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <el-form-item
                  :label="$t('cip.dc.CJudgmentTask.field.CycleSetting')"
                  prop="cron"
                >
                  <el-input readonly :value="form.cron"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-button-group>
                  <el-button @click="cronDialogVisible = true">{{
                    $t("datacenter.dataQuality.monitoringTask.select")
                  }}</el-button>
                  <el-button @click="changeSee" size="mini">{{
                    seeText
                  }}</el-button>
                </el-button-group>
              </el-col>
            </el-row>
            <el-row>
              <el-form-item :label="$t('cip.dc.DComission.tenRun')" v-show="seeTen">
                <el-col span="6" v-for="(item, index) in timeList" :key="index">
                  <div>{{ item }}</div>
                </el-col>
              </el-form-item>
            </el-row>
          </el-collapse-item>

          <el-collapse-item name="2">
            <template slot="title">
              <i
                class="avue-group__title avue-group__header"
                style="
                  color: #999999 !important;
                  font-style: normal;
                  padding: 0 10px;
                "
                ><i
                  class="el-icon-document"
                  style="font-size: 20px; padding: 0 10px"
                ></i>
                {{ $t("cip.dc.zljkcj.field.RequestHeader") }}
              </i>
            </template>
            <el-row>
              <div style="margin-bottom: 20px; padding-left: 30px">
                <el-button
                  icon="el-icon-circle-plus-outline"
                  @click="headerListAddFn"
                  >{{ $t("cip.dc.zljkcj.field.AddRequestHeader") }}
                </el-button>
              </div>
              <!-- <el-col v-for="(item, index) in form.headerList" style="margin-bottom: 20px!important;">
                      key: <el-input placeholder='key' class='new-form-input' v-model="item.key" autocomplete="off"></el-input>
                      value:<el-input placeholder='value' class='new-form-input' v-model="item.value"
                        autocomplete="off"></el-input>
                      <el-button type="danger" icon="el-icon-delete" circle @click="headerListDelFn(index)"></el-button>
                    </el-col> -->
            </el-row>

            <el-table :data="form.headerList" border style="width: 100%">
              <el-table-column label="key:" prop="key">
                <template slot-scope="scope">
                  <el-input
                    v-model="scope.row.key"
                    autocomplete="off"
                    placeholder="key"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column label="value:" prop="value">
                <template slot-scope="scope">
                  <el-input
                    v-model="scope.row.value"
                    autocomplete="off"
                    placeholder="key"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column
                :label="this.$t('cip.cmn.btn.editBtn')"
                prop="address"
              >
                <template slot-scope="scope">
                  <el-button
                    circle
                    icon="el-icon-delete"
                    type="danger"
                    @click="headerListDelFn(scope.row)"
                  ></el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-collapse-item>

          <el-collapse-item :title="$t('cip.dc.DComission.reqbody')" name="3">
            <template slot="title">
              <i
                class="avue-group__title avue-group__header"
                style="
                  color: #999999 !important;
                  font-style: normal;
                  padding: 0 10px;
                "
                ><i
                  class="el-icon-document"
                  style="font-size: 20px; padding: 0 10px"
                ></i>
                {{$t('cip.dc.DComission.reqbody')}}
              </i>
            </template>
            <el-row>
              <el-col span="8">
                <div
                  style="
                    margin-bottom: 20px;
                    padding-left: 30px;
                    display: flex;
                    align-items: center;
                  "
                >
                  <el-button
                    icon="el-icon-circle-plus-outline"
                    @click="headerconfigListAddFn"
                    >{{$t('cip.dc.DComission.Addreqbody')}}
                  </el-button>
                </div>
              </el-col>
              <div>
                <el-table :data="form.configList" border style="width: 100%">
                  <el-table-column label="key:" prop="key">
                    <template slot-scope="scope">
                      <el-input
                        v-model="scope.row.key"
                        autocomplete="off"
                        placeholder="key"
                      ></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column label="value:" prop="value">
                    <template slot-scope="scope">
                      <el-input
                        v-model="scope.row.value"
                        autocomplete="off"
                        placeholder="key"
                      ></el-input>
                    </template>
                  </el-table-column>

                  <el-table-column
                    :label="$t('cip.dc.zljkcj.field.type')"
                    prop="value"
                  >
                    <template slot-scope="scope">
                      <el-select
                        v-model="scope.row.type"
                        style="width: 200px !important"
                      >
                        <el-option
                          :label="$t('cip.dc.zljkcj.field.constant')"
                          :value="1"
                        ></el-option>
                        <el-option :value="2" label="json"></el-option>
                      </el-select>
                    </template>
                  </el-table-column>

                  <el-table-column
                    :label="this.$t('cip.cmn.btn.editBtn')"
                    prop="address"
                  >
                    <template slot-scope="scope">
                      <el-button
                        circle
                        icon="el-icon-delete"
                        type="danger"
                        @click="headerconfigListDelFn(scope.row)"
                      ></el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-row>
          </el-collapse-item>

          <el-collapse-item :title="$t('cip.dc.DComission.ConversionInfo')" name="4">
            <template slot="title">
              <i
                class="avue-group__title avue-group__header"
                style="
                  color: #999999 !important;
                  font-style: normal;
                  padding: 0 10px;
                "
                ><i
                  class="el-icon-document"
                  style="font-size: 20px; padding: 0 10px"
                ></i>
                {{ $t('cip.dc.DComission.ConversionInfo') }}
              </i>
            </template>
            <el-row>
              <el-col span="8">
                <div
                  style="
                    margin-bottom: 20px;
                    padding-left: 30px;
                    display: flex;
                    align-items: center;
                  "
                >
                  <el-button
                    icon="el-icon-circle-plus-outline"
                    @click="AddconfIttransList"
                    >{{ $t('cip.dc.DComission.AddConversionInfo') }}
                  </el-button>
                </div>
              </el-col>
              <div>
                <el-table :data="confIttransList" border style="width: 100%">
                  <el-table-column :label="$t('cip.dc.DComission.fieldName')" prop="fieldName">
                    <template slot-scope="scope">
                      <el-input
                        v-model="scope.row.fieldName"
                        autocomplete="off"
                        :placeholder="$t('cip.dc.DComission.fieldName')"
                      ></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('cip.dc.DComission.targetTable')" prop="targetTable">
                    <template slot-scope="scope">
                      <el-input
                        v-model="scope.row.targetTable"
                        autocomplete="off"
                        :placeholder="$t('cip.dc.DComission.targetTable')"
                      ></el-input>
                    </template>
                  </el-table-column>

                  <el-table-column :label="$t('cip.dc.DComission.srcField')" prop="srcField">
                    <template slot-scope="scope">
                      <el-input
                        v-model="scope.row.srcField"
                        autocomplete="off"
                        :placeholder="$t('cip.dc.DComission.srcField')"
                      ></el-input>
                    </template>
                  </el-table-column>

                  <el-table-column :label="$t('cip.dc.DComission.targetField')" prop="targetField">
                    <template slot-scope="scope">
                      <el-input
                        v-model="scope.row.targetField"
                        autocomplete="off"
                        :placeholder="$t('cip.dc.DComission.targetField')"
                      ></el-input>
                    </template>
                  </el-table-column>

                  <el-table-column
                    :label="this.$t('cip.cmn.btn.editBtn')"
                    prop="address"
                  >
                    <template slot-scope="scope">
                      <el-button
                        circle
                        icon="el-icon-delete"
                        type="danger"
                        @click="DelconfIttransList(scope.row)"
                      ></el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-row>
          </el-collapse-item>

          <el-collapse-item name="5" :title="$t('cip.dc.DComission.toInfo')">
            <template slot="title">
              <i
                class="avue-group__title avue-group__header"
                style="
                  color: #999999 !important;
                  font-style: normal;
                  padding: 0 10px;
                "
                ><i
                  class="el-icon-document"
                  style="font-size: 20px; padding: 0 10px"
                ></i>
                {{ $t('cip.dc.DComission.toInfo') }}
              </i>
            </template>
            <el-row>
              <el-col :span="8">
                <el-form-item :label="$t('cip.dc.DComission.targetTable')">
                  <el-input
                    v-model="injectionData.tablename"
                    autocomplete="off"
                    :placeholder="$t('cip.dc.DComission.targetTable')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('cip.dc.DComission.keycolumn')">
                  <el-input
                    v-model="injectionData.keycolumn"
                    :placeholder="$t('cip.dc.DComission.keycolumn')"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-collapse-item>
        </el-collapse>
      </el-form>
    </div>

    <el-dialog
      :title="$t('datacenter.dataQuality.monitoringTask.cycleSetting')"
      :visible.sync="cronDialogVisible"
    >
      <cron v-model="cron" class="cron"></cron>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="cronDialogConfirm">{{
          $t("submitText")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  itInCode,
  newitInfdetail,
  SavenewitInfList,
  InfsystemList,
} from "@/api/dataAcquisition";
import { listAll, listAll2 } from "@/api/dataAcquisition/DataServices";
import cron from "@/views/components/cron";
import { jxCron } from "@/api/dataAcquisition/index";
import FormLayout from "@/views/components/layout/form-layout";

export default {
  name: "dataCoonfigAdd",
  components: { FormLayout, cron },
  computed: {},
  data() {
    return {
      seeTen:false,
      seeText:this.$t('cip.dc.DComission.seeText1'),
      timeList: [],
      cronDialogVisible: false,
      cron: "",
      idList: [],
      tokenidList: [],
      activeName: ["1", "2", "3", "4", "5"],
      headBtnOptions: [
        {
          label: this.$t("cip.cmn.btn.saveBtn"),
          emit: "head-save",
          type: "button",
          icon: "",
          btnOptType: "save",
          loading: false,
        },
        {
          label: this.$t("cip.cmn.btn.saveBackBtn"),
          emit: "head-save-back",
          type: "button",
          icon: "",
          loading: false,
        },
        {
          label: this.$t("cip.cmn.btn.celBtn"),
          emit: "head-cancel",
          type: "button",
          icon: "",
        },
      ],
      rules: {
        infCode: [
          {
            required: true,
            message: this.$t("cip.dc.zljkcj.field.InterfaceCode"),
            trigger: "change",
          },
        ],
        infName: [
          {
            required: true,
            message: this.$t("cip.dc.zljkcj.field.InterfaceName"),
            trigger: "change",
          },
        ],
        infUrl: [
          {
            required: true,
            message: this.$t("cip.dc.zljkcj.field.InterfaceAddress"),
            trigger: "change",
          },
        ],
        requestMethod: [
          {
            required: true,
            message: this.$t("cip.dc.zljkcj.field.RequestMethod"),
            trigger: "change",
          },
        ],
        synType: [
          {
            required: true,
            message: this.$t("cip.dc.zljkcj.field.AccessMode"),
            trigger: "change",
          },
        ],
        sourceId: [
          {
            required: true,
            message: this.$t("cip.dc.zljkcj.field.SourceSystem"),
            trigger: "change",
          },
        ],
        deltaTime: [
          {
            required: true,
            message: this.$t("cip.dc.zljkcj.field.Maximumtimestamp"),
            trigger: "change",
          },
        ],
        stepTime: [
          {
            required: true,
            message: this.$t("cip.dc.zljkcj.field.startTime"),
            trigger: "change",
          },
        ],
        startTimeField: [
          {
            required: true,
            message: this.$t("cip.dc.zljkcj.field.startTime") + this.$t('cip.dc.DComission.field'),
            trigger: "change",
          },
        ],
        endTimeField: [
          {
            required: true,
            message: this.$t("cip.dc.zljkcj.field.endTime") + this.$t('cip.dc.DComission.field'),
            trigger: "change",
          },
        ],
        tsField: [
          {
            required: true,
            message: this.$t('cip.dc.DComission.tsField'),
            trigger: "change",
          },
        ],
        stepSize: [
          {
            required: true,
            message: this.$t("cip.dc.zljkcj.field.StepLengthdays"),
            trigger: "change",
          },
        ],
        resultData: [
          {
            required: true,
            message: this.$t("cip.dc.zljkcj.field.ReturnDataItem"),
            trigger: "change",
          },
        ],
      },
      form: {
        cron: "",
        id: "", //1
        infCode: "", //1
        infName: "", //1
        infUrl: "", //1
        requestMethod: "", //1
        startTimeField: "", //1
        endTimeField: "", //1
        stepTime: "", //1
        sourceId: "", //1
        tsField: "", //1
        tokenField: "", //1
        headerList: [],
        configList: [],
      },
      formLabelWidth: "120px",
      SystemList: [],
      confIttransList: [], //转换信息
      injectionData: {
        tablename: "",
        keycolumn: "",
      }, //注入信息
    };
  },
  created() {
    this.GetInfsystemList();
    this.getAutoCodeFn();
    this.getItInDetail();
    this.getlistAll();
  },
  methods: {
     changeSee(){
    this.seeTen = !this.seeTen
    if(this.seeTen){
      this.seeText = this.$t('cip.dc.DComission.seeText2')
    }else{
      this.seeText = this.$t('cip.dc.DComission.seeText1')
    }
   },
    MjxCron(cron, count) {
      jxCron({ cron: cron, count: count }).then((res) => {
        this.timeList = res.data.data;
      });
    },
    cronDialogConfirm() {
      this.form.cron = this.cron;
      this.cronDialogVisible = false;
      this.timeList = [];
      this.MjxCron(this.cron, 10);
    },
    getlistAll() {
      //   listAll({}).then((res) => {
      //     this.idList = res.data.data;
      //     this.group[0].column.forEach((element) => {
      //       if (element.prop == "sourceId") {
      //         element.dicData = res.data.data;
      //       }
      //     });
      //   });

      listAll2({}).then((res) => {
        this.tokenidList = res.data.data;
      });
    },
    getItInDetail() {
      if (this.$route.query.type !== "edit") return;
      newitInfdetail({ id: this.$route.query.id }).then((res) => {
        this.form = res.data.data.confItInfParam;
        this.confIttransList = res.data.data.confIttransList;
        this.injectionData = res.data.data.injectionData;
        if (this.form.cron) {
          this.MjxCron(this.form.cron, 10);
        }
      });
    },

    headSave(type) {
      // this.$refs.formLayout.$refs.form.validate((valid) => {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.headBtnOptions[0].loading = true;
          this.headBtnOptions[1].loading = true;
          if (this.form.id) {
            let parms = {
              confItInfParam: this.form,
              confIttransList: this.confIttransList,
              injectionData: this.injectionData,
            };
            SavenewitInfList(parms)
              .then(() => {
                this.$message.success(
                  this.$t("cip.cmn.msg.success.updataSuccess")
                );
                this.headBtnOptions[0].loading = false;
                this.headBtnOptions[1].loading = false;
                if (type == "back") {
                  this.headCancel();
                }
              })
              .catch((res) => {
                this.headBtnOptions[0].loading = false;
                this.headBtnOptions[1].loading = false;
              });
          } else {
            let parms = {
              confItInfParam: this.form,
              confIttransList: this.confIttransList,
              injectionData: this.injectionData,
            };
            SavenewitInfList(parms)
              .then(() => {
                this.$message.success(
                  this.$t("cip.cmn.msg.success.addSuccess")
                );
                this.headBtnOptions[0].loading = false;
                this.headBtnOptions[1].loading = false;
                if (type == "back") {
                  this.headCancel();
                }
              })
              .catch((res) => {
                this.headBtnOptions[0].loading = false;
                this.headBtnOptions[1].loading = false;
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    headerListAddFn() {
      this.form.headerList.push({ key: "", value: "" });
    },
    headerListDelFn(e) {
      let index = "";
      this.form.headerList.forEach((element, idx) => {
        if (element == e) {
          index = idx;
        }
      });
      this.form.headerList.splice(index, 1);
    },
    headerconfigListAddFn() {
      this.form.configList.push({ key: "", value: "", type: null });
    },
    headerconfigListDelFn(e) {
      let index = "";
      this.form.configList.forEach((element, idx) => {
        if (element == e) {
          index = idx;
        }
      });
      this.form.configList.splice(index, 1);
    },

    AddconfIttransList() {
      this.confIttransList.push({
        fieldName: "",
        targetTable: "",
        srcField: "",
        targetField: "",
      });
    },

    DelconfIttransList(e) {
      let index = "";
      this.confIttransList.forEach((element, idx) => {
        if (element == e) {
          index = idx;
        }
      });
      this.confIttransList.splice(index, 1);
    },
    getAutoCodeFn() {
      if (this.$route.query.type == "add") {
        itInCode().then((res) => {
          this.form.infCode = res.data.data;
          this.headerListAddFn();
        });
      }
    },
    GetInfsystemList(name) {
      InfsystemList({ name: name }).then((res) => {
        this.SystemList = res.data.data;
      });
    },
  },
};
</script>

<style scoped></style>
